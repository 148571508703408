// App.js or App.tsx
import React from 'react';

const App = () => {
  return (
    <div>
      <h1>Hello, world!</h1>
    </div>
  );
};

export default App;
